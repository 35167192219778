import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";

import Home from "./pages/Home";
import About from "./pages/About";
import Navbar from "./components/Navbar";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import Product from "./pages/Product";

const theme = createTheme({
  palette: {
    primary: { main: "#000000", light: "#C0C0C2" },
    secondary: { main: "#009B7B" },
  },
  typography: {
    fontFamily: "Open Sans",
  },
});

theme.typography.caption = {
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.3rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.6rem",
  },
};

theme.typography.h3 = {
  [theme.breakpoints.up("xs")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
  },

  [theme.breakpoints.up("lg")]: {
    fontSize: "4rem",
  },
};

theme.typography.h4 = {
  [theme.breakpoints.up("xs")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "2.0rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.5rem",
  },
};

theme.typography.h5 = {
  [theme.breakpoints.up("sm")]: {
    fontSize: "0.9rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.1rem",
  },
};

theme.typography.h6 = {
  [theme.breakpoints.up("xs")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
};

theme.typography.subtitle1 = {
  [theme.breakpoints.up("xs")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
};

theme.typography.subtitle2 = {
  [theme.breakpoints.up("xs")]: {
    fontSize: "0.7rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "0.9rem",
  },
};

theme.typography.body2 = {
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.0rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
  },
};

const App = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <link rel="icon" type="image/png" href="/images/web/favicon.png" />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
          />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
        </Helmet>

        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Navbar />
            <ToastContainer position="bottom-right" theme="dark" />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/product" element={<Product />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </ThemeProvider>
      </div>
    </HelmetProvider>
  );
};

export default App;
