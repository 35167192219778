import React from "react";
import { Helmet } from "react-helmet-async";
import { useMediaQuery, useTheme } from "@mui/material";

const About = () => {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>About us</title>
        <meta
          name="description"
          content="Kweng Hong Packaging Industry Sdn Bhd is commence since 1992. Manufacturers of corrugated carton boxes."
        ></meta>
        <link rel="canonical" href="/about" />
      </Helmet>
      <img src="/images/web/about.jpg" width="100%" />
      {useMediaQuery(theme.breakpoints.down("md")) ? (
        <img src="/images/web/about_banner_mobile.jpg" width="100%" />
      ) : (
        <img src="/images/web/about_banner.jpg" width="100%" />
      )}
    </>
  );
};

export default About;
