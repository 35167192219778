import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, CardMedia, useTheme, Paper, Grid } from "@mui/material";
import ImageLink from "../components/ImageLink";

const Home = () => {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta
          name="description"
          content="Kweng Hong Packaging Industry."
        ></meta>
        <link rel="canonical" href="/" />
      </Helmet>
      <Card style={{ position: "relative" }}>
        <CardMedia component="img" image="/images/web/home.jpg" width="100%" />
        <ImageLink
          sx={{
            top: { xs: "53%", md: "78%", lg: "80%" },
            left: { xs: "48%", md: "50%", lg: "55%" },
            position: "absolute",
            width: { xs: "120px", md: "180px", lg: "200px", xl: "250px" },
            display: { xs: "none", md: "inline" },
          }}
          to="/contact"
          idleImg="/images/web/contact_idle.png"
          activeImg="/images/web/contact_active.png"
        />
        <ImageLink
          sx={{
            top: { xs: "65%", md: "78%", lg: "80%" },
            left: { xs: "48%", md: "72%", lg: "70%" },
            position: "absolute",
            width: { xs: "120px", md: "180px", lg: "200px", xl: "250px" },
            display: { xs: "none", md: "inline" },
          }}
          url="https://www.youtube.com/watch?v=emBmJEOvm-Y"
          idleImg="/images/web/youtube_idle.png"
          activeImg="/images/web/youtube_active.png"
        />
      </Card>

      <Grid
        container
        justifyContent="center"
        columnSpacing={10}
        sx={{ display: { md: "none" }, backgroundColor: "black" }}
      >
        <Grid item xs={6}>
          <ImageLink
            sx={{ width: "100%" }}
            to="/contact"
            idleImg="/images/web/contact_idle.png"
            activeImg="/images/web/contact_active.png"
          />
        </Grid>
        <Grid item xs={6}>
          <ImageLink
            sx={{ width: "100%" }}
            url="https://www.youtube.com/watch?v=emBmJEOvm-Y"
            idleImg="/images/web/youtube_idle.png"
            activeImg="/images/web/youtube_active.png"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
