// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
}

.underline {
  text-decoration-line: underline;
}

a {
  color: green;
}
`, "",{"version":3,"sources":["webpack://./web/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,YAAY;AACd","sourcesContent":["body {\n  margin: 0;\n}\n\n.underline {\n  text-decoration-line: underline;\n}\n\na {\n  color: green;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
