import React from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import axios from "axios";

const Contact = () => {
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const textFieldProps = {
    variant: "standard",
    sx: { width: { xs: "100%" }, marginY: "5px" },
    InputLabelProps: { sx: { fontSize: { xs: "1.1rem", md: "1.4rem" } } },
  };

  const onValid = (data) => {
    axios
      .post("/enquiry", data)
      .then((res) => {
        toast.success("We have received your message");
        reset();
      })
      .catch(({ response, message }) => {
        let inputErrorMsg = "";
        if (response.status == 400) {
          inputErrorMsg = "Invalid Enquiry Input";
          _.chain(response.data)
            .values()
            .map(({ param, msg }) => setError(param, { message: msg }))
            .value();
        }
        toast.error(inputErrorMsg || message);
      });
  };

  return (
    <>
      <Helmet>
        <title>Contact us</title>
        <meta
          name="description"
          content="Make your enquiry for carton boxes."
        ></meta>
        <link rel="canonical" href="/contact" />
      </Helmet>
      <FloatingWhatsApp
        phoneNumber="+60124982115"
        accountName="KH Sales (Winie)"
        avatar="/images/web/favicon.png"
        allowClickAway={true}
        allowEsc={true}
      />
      <img src="/images/web/contact_us.jpg" width="100%" />
      <Grid container justifyContent="center">
        {useMediaQuery(theme.breakpoints.down("md")) ? (
          <>
            <Grid item xs={12}>
              <img src="/images/web/contact_banner01_mobile.jpg" width="100%" />
            </Grid>
            <Grid item xs={12}>
              <img src="/images/web/contact_banner02_mobile.jpg" width="100%" />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <img src="/images/web/contact_banner.jpg" width="100%" />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sx={{
            backgroundImage: useMediaQuery(theme.breakpoints.down("md"))
              ? "url(/images/web/contact_bg_mobile.jpg)"
              : "url(/images/web/contact_bg.jpg)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            bgcolor="#fff"
            sx={{
              marginX: { xs: 0, md: 20 },
              marginY: { xs: 3, md: 10 },
              paddingX: { xs: 3, md: 10 },
              paddingY: { xs: 3, md: 10 },
            }}
          >
            <Typography variant="h4" align="center" paddingBottom={3}>
              Enquiry
            </Typography>
            <Typography variant="caption">
              Our team is dedicated to providing excellent customer service and
              ensuring that all your questions and concerns are addressed in a
              timely manner.
            </Typography>
            <form onSubmit={handleSubmit(onValid)}>
              <TextField
                {...textFieldProps}
                label="Name"
                {...register("name")}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              <br />
              <TextField
                {...textFieldProps}
                label="Email"
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              <br />
              <TextField
                {...textFieldProps}
                label="H/P"
                {...register("phone")}
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
              <br />
              <TextField
                {...textFieldProps}
                label="Company Name"
                {...register("companyName")}
                error={!!errors.companyName}
                helperText={errors.companyName?.message}
              />
              <br />
              <TextField
                {...textFieldProps}
                label="Message"
                multiline
                rows={5}
                {...register("message")}
                error={!!errors.message}
                helperText={errors.message?.message}
              />
              <br />
              <Box paddingY="15px" textAlign="center">
                <Button variant="contained" type="submit" size="large">
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Contact;
