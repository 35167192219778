import React from "react";
import { Typography, Box, Grid, Tooltip } from "@mui/material";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box
      bgcolor="primary.main"
      sx={{ minHeight: { xs: 500, md: 300 } }}
      color="secondary.main"
    >
      <Grid container justifyContent="center" columnSpacing={8}>
        <Grid item xs={12} md={3}>
          <Typography variant="h4" paddingY={2}>
            About us
          </Typography>
          <Typography>
            We are in a corrugated carton and paper line to supply packaging
            needs of our customer by producing carton boxes and paper pallet
            products as requested by their standard of requirements, timely at
            reasonable.
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h4" paddingY={2}>
            Contact
          </Typography>
          <Typography paddingY={1}>
            If you have a question, please get in touch!
          </Typography>
          <Link to="/contact">
            <Typography>Enquiry</Typography>
          </Link>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h4" paddingY={2}>
            Follow us
          </Typography>
          <Box>
            <Tooltip title="facebook">
              <a href="#">
                <img src="/images/facebook_1_64.png" width={64} height={64} />
              </a>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
