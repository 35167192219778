import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  IconButton,
  MenuItem,
  Menu,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AdbIcon from "@mui/icons-material/Adb";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const pages = [
  { label: "Home", to: "/" },
  { label: "About", to: "/about" },
  { label: "Product", to: "/product" },
  { label: "Contact", to: "/contact" },
];

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const { pathname } = location;

  const page = `/${pathname.split("/")[1]}`;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  if (isMobile) {
  } else {
  }

  return (
    <AppBar
      color="transparent"
      // style={{ paddingTop: "5px", paddingBottom: "5px" }}
      sx={{ position: { xs: "static", md: "absolute" } }}
    >
      <Container>
        <Toolbar disableGutters>
          <Box sx={{ width: { xs: "40%", lg: "60%" } }}>
            <img src="/images/web/kh_logo.png" width="100%" />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              keepMounted
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map(({ label, to }) => (
                <MenuItem
                  key={label}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={to}
                  style={{
                    backgroundColor: page === to ? "primary.light " : null,
                  }}
                >
                  <Typography textAlign="center">{label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            {pages.map(({ label, to }) => (
              <Button
                variant="text"
                key={label}
                component={Link}
                sx={{
                  my: 2,
                  display: "block",
                  ":hover": {
                    textDecorationLine: "underline",
                    textDecorationThickness: "1px",
                    color: "#009B7B",
                    textUnderlineOffset: "3px",
                    backgroundColor: "transparent",
                  },
                  marginLeft: "15px",
                }}
                to={to}
              >
                <Typography variant="body2">{label}</Typography>
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
