import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

const ImageLink = ({ sx, to, activeImg, idleImg, url }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{ ...sx, cursor: "pointer" }}
      component="img"
      src={idleImg}
      onMouseOver={(e) => (e.currentTarget.src = activeImg)}
      onMouseOut={(e) => (e.currentTarget.src = idleImg)}
      onClick={
        url
          ? () => {
              console.log(url);
              window.open(url, "_blank", "noreferrer");
            }
          : () => navigate(to)
      }
    />
  );
};

export default ImageLink;
