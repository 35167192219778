import React from "react";
import { createRoot } from "react-dom/client";
import "@fontsource/open-sans";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";

const root = createRoot(document.getElementById("root"));

root.render(<App />);
