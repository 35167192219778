import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, CardMedia, useMediaQuery, useTheme } from "@mui/material";

const Product = () => {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>Product</title>
        <meta
          name="description"
          content="Corrugated board, carton, die-cut box, nesting, layer pad, and edge protector."
        ></meta>
        <link rel="canonical" href="/product" />
      </Helmet>
      <Card style={{ position: "relative" }}>
        <CardMedia
          component="img"
          image="/images/web/product.jpg"
          width="100%"
        />
      </Card>
      {useMediaQuery(theme.breakpoints.down("md")) ? (
        <>
          <img src="/images/web/productm1.jpg" width="100%" />
          <img src="/images/web/productm2.jpg" width="100%" />
        </>
      ) : (
        <img src="/images/web/productpc1.jpg" width="100%" />
      )}
    </>
  );
};

export default Product;
